<template>
	<div
		v-if="visible"
		:id="id"
		@click="fvisible, okfinal"
	>
		<div
			class="backdrop"
			@click="fvisible"
		/>

		<div
			class="dialog"
			@click="fvisible"
		>
			<div class="modal-header text-center">
				<slot name="header" />
			</div>

			<div class="modal-body text-center">
				<slot name="sbody" />
			</div>

			<template v-if="pagamentoViaPix">
				<button
					v-if="!pagamentoGerado && confirmacao && !loadingPix"
					class="col mb-2 button is-primary is-fullwidth is-rounded py-2 btn-atrasado pix"
					@click.prevent="gerarPixClick('copia', doc_id, tempoPix)"
				>
					Copiar código
				</button>

				<button
					v-if="!pagamentoGerado && confirmacao && !loadingPix"
					class="col mb-2 button is-primary is-fullwidth is-rounded py-2 btn-atrasado pix"
					@click.prevent="gerarPixClick('qrcode', doc_id, tempoPix)"
				>
					Escanear QRCODE
				</button>

				<button
					v-if="!pagamentoGerado"
					class="col mb-2 button is-default is-fullwidth is-rounded py-2 btn-atrasado pix">
					Voltar
				</button>

				<button
					v-if="!pagamentoGerado && !confirmacao && !pixFinal"
					class="col mb-2 button is-primary is-fullwidth is-rounded py-2 btn-atrasado pix"
					@click.prevent="confirmarPix"
				>
					Continuar
				</button>

				<button
					v-if="pagamentoGerado && !loadingPix && !pixFinal"
					class="col mb-2 button is-primary is-fullwidth is-rounded py-2 btn-atrasado pix"
					@click.prevent="abrirModalFinal"
				>
					Continuar
				</button>

				<button
					v-if="pixFinal"
					class="col mb-2 button is-default is-fullwidth is-rounded py-2 btn-atrasado pix"
					@click.prevent="fvisible, checkBlockModal(doc_id), close"
				>
					Sair
				</button>
			</template>

			<template v-else-if="atrasado">
				<div class="row">
					<button class="col mb-2 button is-default is-fullwidth is-rounded py-2 btn-atrasado">
						Voltar
					</button>

					<button
						class="col mb-2 button is-primary is-fullwidth is-rounded py-2 btn-atrasado"
						@click.prevent="continuar"
						@click="$root.$emit('open-modal', 'modal')"
					>
						Continuar
					</button>
				</div>
			</template>
			<template v-else>
				<div class="btn">
					<button
						class="mb-2 button is-primary is-fullwidth is-rounded py-2 btn-atrasado"
						@click.prevent="okfinal"
					>
						OK
					</button>
				</div>
			</template>
		</div>
	</div>
</template>

<script>
export default {
	name: "ModalPagamentoBoleto",
	props: [
		'id',
		'atrasado',
		'doc_id',
		'permitir_boleto',
		'permitir_regera',
		'copyClipboard',
		'pagamentoViaPix',
		'confirmacao',
		'pagamentoGerado',
		'loadingPix',
		'pixFinal',
		'tempoPix'
	],
	data() {
		return {
			visible: false,
			dadosPix: null,
			disablePaymentButtons: false
		}
	},
	created() {
		this.$root.$on('open-modal', id => {
			if(this.id === id) {
				this.visible = true;
			}
		});
		this.$root.$on('open-modal2', id => {
			if(this.id === id) {
				this.visible = true;
			}
		});
	},
	methods: {
		fvisible() {
			this.visible = false;
		},
		continuar() {
			if(this.copyClipboard) {
				const download = false;
				const copy = true;
				this.$emit('continuar', download, copy)
			} else {
				const download = true;
				const copy = false;
				this.$emit('continuar', download, copy)
			}
		},
		okfinal() {
			if(this.copyClipboard) {
				const download = false;
				const copy = true;
				this.$emit('okfinal', download, copy)
			} else {
				const download = true;
				const copy = false;
				this.$emit('okfinal', download, copy)
			}
		},
		gerarPixClick(acao, doc_id, tempoPix) {
			const blockTimeInMinutes = tempoPix;
			const currentTime = new Date().getTime();
			const releaseTime = currentTime + blockTimeInMinutes * 60 * 1000;
			localStorage.setItem('releaseTime' + doc_id, releaseTime.toString());

			let docsPixAtivados = localStorage.getItem('docsPixAtivados');
			if (docsPixAtivados) {
				docsPixAtivados += ',' + doc_id;
				localStorage.setItem('docsPixAtivados', docsPixAtivados);
			} else {
				localStorage.setItem('docsPixAtivados', doc_id);
			}

			this.checkBlockModal(doc_id);
			this.$emit('pixGerado', true);

			if (acao == 'qrcode') {
				this.$root.$emit('open-modal', 'modalPixGerar');
			}else{
				this.$emit('copiarPixClick');
				this.abrirModalFinal();
			}
		},
		handleCopiarPixClick() {
			this.$emit('copiarPixClick');
		},
		abrirModalFinal() {
			this.fvisible();
			this.$root.$emit('open-modal', 'modalPixFinal');
		},
		confirmarPix() {
			this.fvisible();
			this.$emit('gerarPixClick', this.doc_id, this.tempoPix);
			this.$root.$emit('open-modal', 'modalPixConfirma');
		},
		checkBlockModal(docId) {
			const releaseTime = localStorage.getItem('releaseTime'+docId);
			if (releaseTime) {
				const currentTime = new Date().getTime();
				if (currentTime <= releaseTime) {
					//this.disablePaymentButtons = true;
					document.getElementById('botao_pix'+docId).disabled = true;
					document.getElementById('botao_cartao_credito'+docId).disabled = true;
					document.getElementById('botao_boleto'+docId).disabled = true;
					document.getElementById('botao_codigo_barras'+docId).disabled = true;
				} else {
					this.disablePaymentButtons = false;
					localStorage.removeItem('releaseTime'+docId);
				}
			}
		},
		clearBlockModal(docId) {
			localStorage.removeItem('releaseTime'+docId);
			this.disablePaymentButtons = false;
		},
		close() {
			this.$emit('close');
			this.visible = false;
		},
		isButtonDisabledModal() {
			const docsPixAtivados = localStorage.getItem('docsPixAtivados');

			if (docsPixAtivados) {
				const docIds = docsPixAtivados.split(',');
				docIds.forEach(doc_id => {
					const releaseTime = localStorage.getItem('releaseTime'+doc_id);
					if (releaseTime) {
						const currentTime = new Date().getTime();
						if (currentTime <= releaseTime) { return true; }
					}
				});
			}
		},
		getTooltipTextModal(docId) {
			if (this.isButtonDisabledModal()) {
				document.getElementById('bloqueio_pix'+docId).style.display = "block";
			}else{
				return '';
			}
		}
	},
}
</script>

<style scoped>
	.btn{
		position: absolute;
		bottom: 10px;
		left: 0;
		right: 0;
		margin: 0 18px;
	}
	.btn-atrasado {
		width: 45%;
		display: inline;
		padding: 0;
		margin: 0;
		margin-right: 5px;
		margin-top: 70px;
	}
	.pix {
		margin-top: 10px;
	}
	.backdrop {
		display: flex ;
		background: rgba(0, 0, 0, 0.25);
		position: fixed;
		left: 0px;
		top: 0px;
		width: 100%;
		height: 100%;
		z-index: 50;
	}
	.dialog {
		text-align: center;
		border-radius: 10px;
		position: fixed;
		top: 20%;
		background: #fff;
		width: 390px;
		height: 430px;
		right: 0;
		left: 0;
		margin: 0 auto;
		padding: 20px;
		z-index: 51;
	}
	.dialog h1 {
		padding-bottom: 30px;
		font-size: 20px;
	}
	.dialog p {
		font-size: 16px;
	}
</style>

