<style scoped>
.fade-enter-active {
	transition: opacity 1s;
}

.fade-leave-active {
	transition: opacity 0s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
	opacity: 0;
}

hr {
	background-color: #c2c2c2;
	height: 1px !important;
}

.open {
	color: #ffb100;
}

.overdue {
	color: #ff1515;
}

@media (max-width: 1020px) {
	.center-on-mobile {
		align-items: center;
	}
}
</style>

<template>
	<transition name="fade">
		<div>
			<b-loading
				v-model="loading"
				:class="[{ 'is-relative': !this.isFullPageLoading }, 'panel-content']"
				:is-full-page="isFullPageLoading"
				:can-cancel="false"
			/>
			<div
				v-show="isFullPageLoading || !loading"
				class="pt-3"
			>
				<form
					novalidate
					@submit.prevent="generatePDF"
				>
					<div class="columns is-desktop">
						<div class="column is-3-desktop is-12">
							<UIInput
								id="exs-ano"
								v-model="form.ano"
								type="select"
								:options="years"
								label="Ano"
								placeholder="Ano"
								:validation="$v.form.ano"
								:error="{
									required: '*Campo obrigatório.'
								}"
								required
							/>
						</div>

						<div class="column is-offset-1-desktop is-3-desktop is-12">
							<UIInput
								id="exs-semestre"
								v-model="form.semestre"
								type="select"
								:options="[
									{ label: 'Primeiro', value: '1' },
									{ label: 'Segundo', value: '2' }
								]"
								label="Semestre"
								placeholder="Semestre"
								:validation="$v.form.semestre"
								:error="{
									required: '*Campo obrigatório.'
								}"
								required
							/>
						</div>

						<div
							class="column is-offset-1-desktop is-4-desktop is-12 is-flex is-justify-content-center"
						>
							<button
								type="submit"
								class="my-2 button is-primary is-rounded"
							>
								Gerar Extrato
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</transition>
</template>

<script>
import UIInput from '@components/uiinput';
import http from '@/commons/http';
import { required } from 'vuelidate/lib/validators';
import { pdfDownloader } from '@commons/helpers';
import { isNil } from 'lodash';
import { mapGetters } from 'vuex';

export default {
	name: 'ExtratoSemestral',
	components: {
		UIInput
	},
	data() {
		return {
			loading: false,
			isFullPageLoading: false,
			form: {
				ano: null,
				semestre: null
			},
			periodos: [],
			matricula: null,
			nensId: null,
			cursId: null,
			viacSequencia: null,
			habiId: null,
		};
	},
	computed: {
		...mapGetters('vinculosAcademicos', [
			'getVinculoSelecionado'
		]),
		years() {
			const years = [];

			this.periodos.forEach(periodo => {
				const yearExists = years.find(year => year.value == periodo.peri_ano);

				if (!yearExists) {
					years.push({ label: periodo.peri_ano, value: periodo.peri_ano });
				}
			});

			return years.sort((a, b) => a - b);
		}
	},
	async created() {
		this.matricula = this.$store.state.alunoMatricula;
		this.vinculoSelecionado = this.getVinculoSelecionado;
		this.setVinculoByFilter();
		await this.updatePeriodos();
	},
	methods: {
		async updatePeriodos() {
			this.loading = true;

			if (isNil(this.vinculoSelecionado)) {
				this.loading = false;
				return;
			}

			try {
				const periodoData = await http.get(
					'/v1/aluno/pagamentos/periodo-extrato',
					{
						alun_matricula: this.matricula,
						nens_id: this.nensId,
						curs_id: this.cursId
					}
				);

				this.periodos = periodoData.data.message;
			} catch (error) {
				this.periodos = [];

				this.$buefy.toast.open({
					message: 'Não foram encontrados períodos para o curso selecionado',
					type: 'is-danger'
				});
			}

			this.loading = false;
		},

		async generatePDF() {
			this.loading = true;
			this.isFullPageLoading = true;

			if (isNil(this.vinculoSelecionado)) {
				this.$buefy.toast.open({
					message:
						'Você deve selecionar um vínculo para emitir extrato semestral!',
					type: 'is-danger'
				});
				this.loading = false;
				this.isFullPageLoading = false;
				return;
			}

			this.$v.form.$touch();

			if (this.$v.form.$pending || this.$v.form.$error) {
				this.loading = false;
				this.isFullPageLoading = false;
				return;
			}

			const periodo = this.periodos.find(periodo => {
				return (
					periodo.peri_ano == this.form.ano &&
					periodo.peri_periodo == this.form.semestre
				);
			});

			if (!periodo) {
				this.$buefy.toast.open({
					message: 'Não foram encontrados extratos nesse período selecionado',
					type: 'is-danger'
				});

				this.loading = false;
				this.isFullPageLoading = false;
				return false;
			}

			try {
				const response = await http.get(
					'/v1/aluno/pagamentos/extrato-pdf',
					{
						alun_matricula: this.matricula,
						nens_id: this.nensId,
						curs_id: this.cursId,
						habi_id: this.habiId,
						viac_sequencia: this.viacSequencia,
						peri_id: periodo.peri_id
					},
					{},
					'blob'
				);

				if (response.status != 200) {
					this.$buefy.toast.open({
						message: 'Não há extrato semestral disponível',
						type: 'is-danger'
					});

					this.loading = false;
					this.isFullPageLoading = false;

					return;
				}

				pdfDownloader(response.data, 'extrato-semestral.pdf');
				this.loading = false;
				this.isFullPageLoading = false;
			} catch (error) {
				this.$buefy.toast.open({
					message: 'Não há extrato semestral disponível',
					type: 'is-danger'
				});

				this.loading = false;
				this.isFullPageLoading = false;
			}
		},
		setVinculoByFilter() {
			if (!isNil(this.vinculoSelecionado)) {
				this.nensId = this.vinculoSelecionado.nens_id;
				this.cursId = this.vinculoSelecionado.curs_id;
				this.viacSequencia = this.vinculoSelecionado.viac_sequencia;
				this.habiId = this.vinculoSelecionado.habi_id;
			}
		}
	},
	validations: {
		form: {
			ano: {
				required
			},
			semestre: {
				required
			}
		}
	}
};
</script>
