<style scoped>
.fade-enter-active {
	transition: opacity 1s;
}

.fade-leave-active {
	transition: opacity 0s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
	opacity: 0;
}

hr {
	background-color: #c2c2c2;
	height: 1px !important;
}

.payed {
	color: #37c36f;
}

@media (max-width: 1020px) {
	.center-on-mobile {
		text-align: center;
	}
}

.justify-with-image {
	margin-top: -40px;
	line-height: 50px;
	color: #26496c;
	font-weight: bold;
}

.link-atraso, .link-atraso:hover, .link-atraso:focus{
	font-weight: 700;
	color: #ff1515 !important;
}

.link-vencer, .link-vencer:hover, .link-vencer:focus{
	font-weight: 700;
	color: #00ae8e !important;
}

.link-atraso:hover, .link-atraso:focus, .link-vencer:hover, .link-vencer:focus{
	filter: brightness(40%) !important;
}
.nf-list-item {
  list-style-type: disc;
  margin-left: 20px;
}
</style>
<template>
	<transition name="fade">
		<div>
			<b-notification
				:closable="false"
				class="notification"
			>
				<b-loading
					v-model="loading"
					:is-full-page="true"
					:can-cancel="false"
				/>
			</b-notification>

			<div
				v-if="data.length == 0"
				class="py-5 is-flex is-justify-content-center"
			>
				<div class="is-flex is-flex-direction-column is-align-items-center">
					<div class="column is-5">
						<Icon
							class="primary-color"
							file="puzzle"
							size="100%"
						/>
					</div>
					<h3
						class="is-size-1 is-size-2-mobile has-text-centered justify-with-image"
					>
						Nenhum pagamento efetuado
					</h3>
					<p class="column is-10 is-size-5 has-text-centered">
						Identificamos que você ainda não possui nenhum pagamento. Assim que
						o seu primeiro pagamento for realizado ele aparecerá aqui. Fique
						ligado! 😉
					</p>
					<p class="column is-10 is-size-5 has-text-centered">
						<span v-if="showAtrasados">Clique na aba “<a class="link-atraso" href="#" @click="goToTab(0)">Em Atraso</a>” para verificar se não há pagamentos atrasados. <br></span>
						Clique na aba “<a class="link-vencer" href="#" @click="goToTab(1)">A Vencer</a>” para conferir todos os pagamentos a vencer.
					</p>
				</div>
			</div>

			<div
				v-for="historico in data"
				:key="historico.doc_id"
				class="pt-3"
			>
				<div class="columns is-desktop py-4">
					<div
						class="column is-6-desktop is-12 is-flex is-flex-direction-column"
					>
						<div class="payed center-on-mobile">
							<b-icon
								size="is-small"
								icon="checkbox-marked-circle-outline"
							/>
							<span class="is-size-6 has-text-weight-bold"> Fatura Paga </span>
						</div>

						<p class="is-size-4 center-on-mobile has-text-weight-bold pb-4">
							R${{ historico.valor_pago }}
						</p>
						<p class="is-size-6">
							Vencimento:
							<strong>{{ parseDate(historico.data_vencimento) }}</strong>
						</p>

						<p
							v-if="historico.doc_tipo.toLowerCase() == 'm'"
							class="is-size-6"
						>
							Mês referência:
							<strong>{{
								historico.mes_referencia.replace('/', ' / ')
							}}</strong>
						</p>

						<p
							v-if="historico.doc_tipo.toLowerCase() != 'm'"
							class="is-size-6"
						>
							Tipo:
							<strong>
								{{
									historico.doc_tipo_parse
										.toLowerCase()
										.split(' ')
										.map((s) => s.charAt(0).toUpperCase() + s.substring(1))
										.join(' ')
								}}
							</strong>
						</p>

						<p class="is-size-6">
							Curso: <strong>{{ historico.curs_nome }}</strong>
						</p>

						<p class="is-size-6">
							Período letivo: <strong>{{ historico.periodo_letivo }}</strong>
						</p>

						<p class="is-size-6">
							Pagamento:
							<strong>via {{ historico.forma_pagamento }}
								{{ historico.final_cartao }}</strong>
						</p>

						<p class="is-size-6">
							Data de pagamento:
							<strong>{{ parseDate(historico.data_pagamento) }}</strong>
						</p>

						<DetalhesFatura
							:data="historico.detalhes"
							:doc-id="historico.doc_id"
						/>
					</div>

					<div
						class="column vertical-divider is-1-desktop is-hidden-touch"
					/>

					<div
						class="column is-5-desktop is-12 is-flex is-flex-direction-column is-align-items-center"
					>
						<b-button
							v-if="historico.forma_pagamento.toLowerCase() == 'boleto'"
							type="submit"
							class="mb-4 button is-primary is-rounded is-fullwidth"
							@click="
								handleInvoice(
									historico.doc_id,
									historico.forma_pagamento.toLowerCase()
								)
							"
						>
							{{
								historico.forma_pagamento.toLowerCase() == 'boleto'
									? 'Boleto Original'
									: 'Comprovante do Cartão'
							}}
						</b-button>
						<b-button
							v-else
							type="submit"
							class="mb-4 button is-primary is-rounded is-fullwidth"
							@click="
								handleInvoice(
									historico.pgto_id,
									historico.forma_pagamento.toLowerCase()
								)
							"
						>
							{{
								historico.forma_pagamento.toLowerCase() == 'boleto'
									? 'Boleto Original'
									: 'Comprovante do Cartão'
							}}
						</b-button>
						<b-button
							:disabled="!(historico.nota_fiscal.link_retorno || historico.nota_fiscal.mensagem)"
							class="mb-4 button is-fullwidth is-primary is-rounded is-outlined"
							@click.prevent="openLinkOrError(historico.nota_fiscal.link_retorno, historico.nota_fiscal.mensagem, historico.nota_fiscal)"
						>
							Nota Fiscal
						</b-button>
					</div>
				</div>

				<hr class="divider-horizontal">
			</div>
			<b-modal 
				v-if="modalData" 
				:active="true"
				:is-full-page="true"
				:can-cancel="false"
			>
				<b-loading
					v-model="loading"
					:is-full-page="true"
					:can-cancel="false"
				/>
				<div class="card">
					<div class="card-content">
						<h1 class="is-flex title is-1 has-text-centered">
							Nota Fiscal
						</h1>
						<p>
							<strong>Informações para nota fiscal.</strong><br>
							Informamos que a nota fiscal está disponível para impressão.<br>
							Acesse em:
						</p>
						<a 
							:href="modalData.link_retorno" 
							target="_blank"
						>
							{{ modalData.link_retorno }}
						</a>
						<p>
							<strong>Algumas informações abaixo podem ser necessárias para acesso:</strong>
						</p>
						<ul>
							<li class="nf-list-item">
								<strong>CNPJ Prestador:</strong> {{ modalData.cnpjPrestador }}
							</li>
							<li class="nf-list-item">
								<strong>IM Prestador:</strong> {{ modalData.imPrestador }}
							</li>
							<li class="nf-list-item">
								<strong>Número NFS-e:</strong> {{ modalData.numeroNf }}
							</li>
							<li class="nf-list-item">
								<strong>Data de emissão:</strong> {{ modalData.dataEmissao }}
							</li>
							<li class="nf-list-item">
								<strong>Número do RPS:</strong> {{ modalData.numeroRps }}
							</li>
							<li class="nf-list-item">
								<strong>Série RPS:</strong> {{ modalData.serieRps }}
							</li>
							<li class="nf-list-item">
								<strong>Código de verificação:</strong> {{ modalData.codigoAutenticacao }}
							</li>
							<li class="nf-list-item">
								<strong>Valor do Serviço:</strong> {{ modalData.valorServico }}
							</li>
						</ul>
						<b-button
							class="mb-2 mt-5"
							label="Fechar"
							type="is-primary is-rounded"
							@click="closeNfNotification"
						/>
					</div>
				</div>
			</b-modal>
		</div>
	</transition>
</template>

<script>
import moment from 'moment';
import Icon from '@components/Icon';
import DetalhesFatura from '@components/meus_pagamentos/DetalhesFatura.vue'
import { mapActions } from 'vuex';

export default {
	name: 'Historico',
	components: {
		Icon,
		DetalhesFatura
	},
	props: {
		data: {
			type: Array,
			default: () => [],
		},
		showAtrasados: {
			type: Boolean,
			required: false,
			default: false,
		}
	},
	data() {
		return {
			loading: false,
			modalData: null,
		};
	},
	methods: {
		...mapActions('pagamentos', [
			'gerarComprovanteBoleto',
			'gerarComprovanteCartao'
		]),
		goToTab(index) {
			this.$emit("goToTab", index);
		},
		async handleInvoice(id, paymentType) {
			const response = paymentType === 'boleto' ?
				await this.gerarComprovanteBoleto(id) :
				await this.gerarComprovanteCartao(id);

			if (response) return;

			this.$buefy.toast.open({
				message: 'Não existe comprovante para esse pagamento!',
				type: 'is-danger',
			});

		},
		parseDate(date) {
			return moment(date).format('DD/MM/YYYY');
		},
		openLinkOrError(link, error, infos) {
			if (error) {
				this.$store.dispatch('ui/toast/openError', error); 

				return;
			}

			this.modalData = {
				link_retorno: infos.link_retorno,
				cnpjPrestador: infos.cnpj_prestador,
				imPrestador: infos.im_prestador,
				numeroNf: infos.numero_nf,
				dataEmissao: infos.data_emissao,
				numeroRps: infos.numero_rps,
				serieRps: infos.serie_rps,
				codigoAutenticacao: infos.codigo_verificacao,
				valorServico: infos.valor_nota
			};
		},
		closeNfNotification() {
			this.modalData = null; // Ou qualquer valor que indique que a modal deve ser fechada
		},
	},
};
</script>
