<template>
	<div class="columns">
		<b-loading
			v-model="isLoading"
			:is-full-page="true"
			:can-cancel="false"
		/>

		<transition name="fade">
			<div
				class="column is-10-tablet is-offset-1-tablet is-10-fullhd"
			>
				<Title>
					Meus Pagamentos
				</Title>

				<div
					v-if="!isLoading"
					class="panel"
				>
					<div class="panel-header">
						<b-tabs v-model="activeTab">
							<b-tab-item
								ref="tab-0"
								:visible="showAtrasadosTab"
								header-class="tab-atrasados"
							>
								<template #header>
									<Icon
										class="tabs-custom-icon"
										file="warning"
										size="20"
									/>
									<span>Em atraso</span>
								</template>
							</b-tab-item>
							<b-tab-item
								ref="tab-1"
								header-class="tab-abertos"
							>
								<template #header>
									<Icon
										class="tabs-custom-icon"
										file="hourglass"
										size="20"
									/>
									<span>A Vencer</span>
								</template>
							</b-tab-item>
							<b-tab-item
								ref="tab-2"
								header-class="tab-pagos"
							>
								<template #header>
									<Icon
										class="tabs-custom-icon"
										file="check"
										size="20"
									/>
									<span>Pagos</span>
								</template>
							</b-tab-item>
							<b-tab-item
								ref="tab-3"
								label="Extrato semestral"
							/>
							<b-tab-item
								ref="tab-4"
								label="Comprovante IRPF"
							/>
						</b-tabs>
					</div>

					<transition name="fade">
						<div>
							<ParcelamentoFatura
								v-if="getActiveTab == TabEnum.ATRASADOS && showAtrasadosTab"
								class="parcelamento-fatura"
							/>
							<div
								v-if="getActiveTab == TabEnum.ATRASADOS && showAtrasadosTab"
								class="panel-content is-relative panel-atrasados"
							>
								<ListaPagamentosPendentes
									list-type="atrasados"
									:data="getAtrasados"
								/>
							</div>
						</div>
					</transition>
					<transition name="fade">
						<div
							v-if="getActiveTab == TabEnum.ABERTOS"
							class="panel-content is-relative"
						>
							<ListaPagamentosPendentes
								list-type="abertos"
								:data="getAbertos"
								:show-atrasados="getAtrasados.length > 0"
								:show-pagos="getPagos.length > 0"
								@goToTab="goToTab"
							/>
						</div>
					</transition>
					<transition name="fade">
						<div
							v-if="getActiveTab == TabEnum.PAGOS"
							class="panel-content"
						>
							<Historico
								:data="getPagos"
								:show-atrasados="getAtrasados.length > 0"
								@goToTab="goToTab"
							/>
						</div>
					</transition>
					<transition name="fade">
						<div
							v-if="getActiveTab == TabEnum.EXTRATO"
							class="panel-content"
						>
							<ExtratoSemestral v-if="getActiveTab == TabEnum.EXTRATO" />
						</div>
					</transition>
					<transition name="fade">
						<div
							v-if="getActiveTab == TabEnum.IRPF"
							class="panel-content"
						>
							<ComprovanteIrpf
								v-if="getActiveTab == TabEnum.IRPF"
								:historico="getPagos"
							/>
						</div>
					</transition>
				</div>

				<CartaoModalOverdue
					v-if="showCartaoOverdueModal == 1"
					@close="showCartaoOverdueModal++"
				/>
			</div>
		</transition>
	</div>
</template>

<script>
import ListaPagamentosPendentes from '@components/meus_pagamentos/ListaPagamentosPendentes';
import Historico from '@components/meus_pagamentos/Historico';
import ExtratoSemestral from '@components/meus_pagamentos/ExtratoSemestral';
import Icon from '@components/Icon';
import ParcelamentoFatura from '@components/meus_pagamentos/ParcelamentoFatura';
import ComprovanteIrpf from '@components/meus_pagamentos/ComprovanteIrpf';
import Title from '@components/Title';
import { mapActions, mapGetters, mapMutations } from 'vuex';
import CartaoModalOverdue from '@components/meus_pagamentos/pagamento/CartaoModalOverdue';

const TabEnum = {
	ATRASADOS: 0,
	ABERTOS: 1,
	PAGOS: 2,
	EXTRATO: 3,
	IRPF: 4,
};

export default {
	name: 'MeusPagamentos',
	components: {
		ListaPagamentosPendentes,
		Historico,
		ExtratoSemestral,
		Icon,
		ParcelamentoFatura,
		ComprovanteIrpf,
		Title,
		CartaoModalOverdue,
	},
	data() {
		const showCartaoOverdueModal =
			this.$store.getters['pagamentos/getAtrasados'].length > 0
			&& this.$store.getters['pagamentos/getAtrasados'].some(doc =>
				doc.doc_tipo == 'M'
				&& doc.recorrencia_ativa
			)
				? 1
				: 0;

		return {
			activeTab: TabEnum.ABERTOS,
			TabEnum,
			showCartaoOverdueModal,
		};
	},
	computed: {
		...mapGetters('pagamentos', [
			'getAbertos',
			'getPagos',
			'getAtrasados',
			'contemDados',
			'isLoading'
		]),
		getActiveTab() {
			return this.activeTab;
		},
		showAtrasadosTab() {
			return this.getAtrasados.length > 0;
		},
	},
	watch: {
		activeTab(value) {
			if (value === TabEnum.EXTRATO || value === TabEnum.IRPF) {
				this.setPermitirFiltrarTodos(false);
				return;
			}

			this.setPermitirFiltrarTodos(true);
		},
		isLoading(value) {
			if (value) {
				return;
			}

			if (
				this.activeTab === TabEnum.ATRASADOS
				|| this.activeTab === TabEnum.ABERTOS
				|| this.activeTab === TabEnum.PAGOS
			) {
				this.defaultTabOnOpen();
			}
		},
		showAtrasadosTab(value) {
			if (!value) {
				return;
			}

			const hasTuition = this.getAtrasados.some(doc =>
				doc.doc_tipo == 'M'
				&& doc.recorrencia_ativa
			);

			if (hasTuition) {
				this.showCartaoOverdueModal++;
			}
		},
	},
	async created() {
		if (!this.contemDados) {
			await this.todosPagamentos();
		}

		this.setPermitirFiltrarTodos(true);
		this.defaultTabOnOpen();
	},
	methods: {
		...mapActions('pagamentos', [
			'todosPagamentos',
		]),
		...mapMutations('vinculosAcademicos', [
			'setPermitirFiltrarTodos'
		]),
		goToTab(index){
			this.activeTab = index;
		},
		defaultTabOnOpen() {
			const routerParameters = this.$route.params;

			if (routerParameters?.tab) {
				this.activeTab = routerParameters.tab;
				return;
			}

			if (this.showAtrasadosTab) {
				this.activeTab = TabEnum.ATRASADOS;
			} else {
				this.activeTab = TabEnum.ABERTOS;
			}
		}
	}
};
</script>

<style lang="scss" scoped>
@import '@styles/_variables';
.notification {
	background: transparent;
	position: absolute;
}

.panel {
	background: $white;
	background: var(--white);
	border-radius: 8px;
	box-shadow: none;

	&-header {
		border-radius: 8px 8px 0 0;
		background: #f2f2f2;
		padding: 10px 20px 0 20px;
	}

	&-content {
		padding: 30px 48px;
	}
}

.student {
	color: #173048 !important;
	font-weight: 700 !important;
}


::v-deep .tab-abertos.is-active {
	a {
		border-bottom-color: #ffb100 !important;
	}

	.tabs-custom-icon .hourglass-sand {
		fill: #f6cb5c !important;
	}

	.tabs-custom-icon .hourglass-base {
		fill: #9f7509 !important;
	}

	.tabs-custom-icon .hourglass-glass {
		fill: #d3d3d3 !important;
	}

	.tabs-custom-icon .hourglass-reflex {
		fill: #fff2c7 !important;
	}
}

::v-deep .tab-pagos.is-active {
	a {
		border-bottom-color: #00ae8e !important;
	}

	.tabs-custom-icon .check {
		fill: #00796a !important;
	}
}

::v-deep .tabs-custom-icon {
	position: relative;
	top: 3px;
	margin-right: 8px;
}

::v-deep .b-tabs .tab-atrasados.is-active {
	a {
		border-bottom-color: #ff1515 !important;
	}

	.tabs-custom-icon .warning {
		fill: #ff1515 !important;
	}
}
</style>
