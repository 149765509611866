<template>
	<transition name="fade">
		<div>
			<b-notification
				:closable="false"
				class="notification"
			>
				<b-loading
					v-model="loading"
					:is-full-page="true"
					:can-cancel="false"
				/>
			</b-notification>
			<div class="pt-3">
				<form
					novalidate
					@submit.prevent="generatePDF"
				>
					<div class="columns is-desktop">
						<div class="column is-3-desktop is-12">
							<UIInput
								id="cir-ano"
								v-model="form.year"
								type="select"
								:options="years"
								label="Ano"
								placeholder="Ano"
								:validation="$v.form.year"
								:error="{
									required: '*Campo obrigatório.'
								}"
								required
								value-as-key
							/>
						</div>

						<div class="column is-offset-1-desktop is-3-desktop is-12">
							<UIInput
								id="cir-pessoa"
								v-model="form.person"
								type="select"
								:options="persons"
								label="Responsável Financeiro"
								placeholder="Responsável Financeiro"
								:validation="$v.form.person"
								:error="{
									required: '*Campo obrigatório.'
								}"
								required
								value-as-key
							/>
						</div>

						<div
							class="column is-offset-1-desktop is-4-desktop is-12 is-flex is-justify-content-center"
						>
							<button
								type="submit"
								class="my-2 button is-primary is-rounded"
							>
								Emitir comprovante IRPF
							</button>
						</div>
					</div>
				</form>
			</div>
		</div>
	</transition>
</template>

<script>
import UIInput from '@components/uiinput';
import http from '@/commons/http';
import { required } from 'vuelidate/lib/validators';
import { pdfDownloader } from '@/commons/helpers';
import { isNil, uniqBy } from 'lodash';
import { mapGetters } from 'vuex';

export default {
	name: 'ComprovanteIrpf',
	components: {
		UIInput
	},
	props: {
		historico: {
			type: Array,
			default: () => []
		}
	},
	data() {
		return {
			loading: false,
			form: {
				year: null,
				person: null
			},
			matricula: null,
			nensId: null,
			cursId: null
		};
	},
	computed: {
		...mapGetters('vinculosAcademicos', [
			'getVinculoSelecionado'
		]),
		years() {
			if (isNil(this.historico || this.historico.length <= 0)) {
				return [];
			}

			let years = [];

			try {
				this.historico.map(doc => {
					if (doc.data_pagamento == null || doc.data_pagamento == undefined) {
						return;
					}

					const year = doc.data_pagamento.split('-')[0];

					years.push({
						label: year,
						value: year
					});
				});

				years.sort((first, second) => first.value - second.value).reverse();

				return uniqBy(years, 'value');
			} catch (error) {
				return [];
			}
		},
		persons() {
			if (!this.form.year) {
				return [];
			}

			const persons = [];

			this.historico
				.filter(doc => doc.data_pagamento != null && doc.data_pagamento != undefined && doc.data_pagamento.split('-')[0] == this.form.year)
				.map(doc =>
					persons.push({
						label: doc.pess_nome_responsavel,
						value: doc.pess_id_responsavel
					})
				);

			return uniqBy(persons, 'value');
		},
		vinculoAtivo() {
			return this.getVinculoSelecionado;
		}
	},
	beforeMount() {
		this.setVinculoByFilter();
	},
	methods: {
		async generatePDF() {
			this.loading = true;

			if (isNil(this.vinculoAtivo)) {
				this.$buefy.toast.open({
					message:
						'Você deve selecionar um vínculo para emitir comprovante IRPF!',
					type: 'is-danger'
				});

				this.loading = false;

				return;
			}

			this.$v.form.$touch();

			if (this.$v.form.$pending || this.$v.form.$error) {
				this.loading = false;
				return;
			}

			try {
				const payload = {
					alun_matricula: this.matricula,
					nens_id: this.nensId,
					curs_id: this.cursId,
					ano_base: this.form.year,
					pess_id_responsavel: this.form.person
				};

				const response = await http.post(
					'/v1/aluno/pagamentos/declaracao-irpf-pdf',
					new URLSearchParams(Object.entries(payload)).toString(),
					{},
					'blob'
				);

				if (response.status != 200) {
					this.$buefy.toast.open({
						message: 'Erro ao emitir comprovante IRPF',
						type: 'is-danger'
					});

					this.loading = false;

					return;
				}

				pdfDownloader(response.data, 'comprovante-irpf.pdf');
			} catch (e) {
				this.$buefy.toast.open({
					message: 'catch - Erro ao emitir comprovante IRPF',
					type: 'is-danger'
				});
			}
			this.loading = false;
		},
		setVinculoByFilter() {
			const alunoMatricula = this.$store.state.alunoMatricula;

			if (!isNil(this.vinculoAtivo)) {
				this.nensId = this.vinculoAtivo.nens_id;
				this.cursId = this.vinculoAtivo.curs_id;
				this.matricula = alunoMatricula;
				this.form.curso = `${this.nensId}-${this.cursId}`;
			}
		}
	},
	validations: {
		form: {
			year: {
				required
			},
			person: {
				required
			}
		}
	}
};
</script>

<style scoped>
.fade-enter-active {
	transition: opacity 1s;
}

.fade-leave-active {
	transition: opacity 0s;
}

.fade-enter, .fade-leave-to /* .fade-leave-active em versões anteriores a 2.1.8 */ {
	opacity: 0;
}

hr {
	background-color: #c2c2c2;
	height: 1px !important;
}

.open {
	color: #ffb100;
}

.overdue {
	color: #ff1515;
}

@media (max-width: 1020px) {
	.center-on-mobile {
		align-items: center;
	}
}
</style>
