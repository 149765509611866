<template>
	<div>
		<div class="py-5 background-ie flex-change-directions is-flex is-justify-content-space-evenly is-align-items-center is-mobile">
			<p class="column pt-3 pb-5 is-size-3 is-5-desktop is-offeset-1">
				Quer parcelar suas faturas em atraso no cartão de crédito?
			</p>
			<b-button
				class="primary-color mb-4 button is-rounded"
				@click="parcelamentoFatura()"
			>
				Comece aqui
			</b-button>
		</div>
	</div>
</template>

<script>
export default {
	name: 'ParcelamentoFatura',
	methods: {
		parcelamentoFatura() {
			window.open('/financeiro/PagamentoOnlineVindiDocs.php', '_blank');
		}
	}
}
</script>

<style scoped>
.flex-change-directions {
    flex-direction: row;
}

@media (max-width: 1024px) { 
   .flex-change-directions {
       flex-direction: column;
   }

   .flex-change-directions > p {
       text-align: center;
   }
}

.background-ie {
    background-color: var(--primary);
}

.button {
    background-color: white !important;
    border: none;
}

.button:hover {
    background-color: white !important;
    color: var(--primary) !important;
    border: none;
}

p {
    color: white;
}

</style>