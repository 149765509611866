<template>
	<b-collapse
		:open="open"
		:aria-id="docId"
		class="is-flex is-flex-direction-column center-on-mobile"
	>
		<template #trigger="props">
			<div class="pt-5">
				<b-icon
					class="primary-color"
					:icon="
						props.open
							? 'minus-circle-outline'
							: 'plus-circle-outline'
					"
				/>
				<span
					:aria-controls="docId"
					class="is-size-5 has-text-weight-bold"
				>
					Veja sua fatura detalhada
				</span>
			</div>
		</template>
		<div
			class="content is-flex is-flex-direction-column px-5 py-3 left-on-mobile full-width"
		>
			<div
				v-if="dataOriginal"
				class="columns is-mobile"
			>
				<div class="column is-9 my-0 py-0 animatedOverflow">
					Vencimento original <strong>(Parcela {{ valorOriginal[0].parcela }})</strong>
				</div>

				<div class="column is-3 my-0 py-0">
					<strong>{{ dataOriginal }}</strong>
				</div>
			</div>

			<div
				v-if="valorOriginal.length > 0"
				class="columns is-mobile"
			>
				<div class="column is-9 my-0 py-0 animatedOverflow">
					Valor original <strong>(Parcela {{ valorOriginal[0].parcela }})</strong>
				</div>

				<div class="column is-3 my-0 py-0">
					<strong>R${{ valorOriginal[0].valor }}</strong>
				</div>
			</div>
			<div
				v-for="(detalhe, index) in detalhes"
				:key="index"
				class="columns is-mobile"
			>
				<div class="column is-9 my-0 py-0 animatedOverflow">
					<span
						v-if="detalhe.valor > '0'"
						class="subtraction"
					>(+)</span>
					<span
						v-else
						class="addition"
					>(-)</span>
					{{ detalhe.tipo | capitalize }}
				</div>
				<div class="column is-3 my-0 py-0">
					<strong>R${{ detalhe.valor }}</strong>
				</div>
			</div>
		</div>
	</b-collapse>
</template>

<script>
import { capitalize } from 'lodash';

export default {
	name: "DetalhesFatura",
	filters: {
		capitalize(value) {
			return capitalize(value);
		},
	},
	props: {
		docId: {
			type: [Number, String],
			default: null
		},
		data: {
			type: Array,
			default: () => [],
		},
		dataOriginal: {
			default: null
		}
	},
	data() {
		return {
			open: false,
			detalhes: [],
			valorOriginal: [],
			vencimentoOriginal: []
		}
	},
	created() {
		this.valorOriginal = this.data.filter(detalhe => detalhe.tipo.toLowerCase() === 'encargos educacionais');
		this.detalhes = this.data.filter(detalhe => detalhe.tipo.toLowerCase() !== 'encargos educacionais');
	}
}
</script>

<style scoped>
	@media (max-width: 1020px) {
		.full-width {
			padding-left: 0px !important;
			padding-right: 0px !important;
			text-align: left;
			font-size: 13px !important;
		}
	}


  .subtraction {
    color: #ff1515 !important;
  }

  .addition {
    color: #37c36f !important;
  }

  .full-width {
    width: 100%;
  }
</style>
